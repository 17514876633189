<script setup lang="ts">
/**
 * @note I wanted to call this `Link` but Nuxt / useHead
 * already uses `<Link>` for the actual `<link>` tag.
 * I called it `ALink` to refer to a rendered `<a>` tag.
 */
import { NuxtLink } from '#components'
import { type NuxtLinkProps } from 'nuxt/app'
import { computed, useRoute } from '#imports'
import { type RouteLocationPathRaw } from 'vue-router'

const route = useRoute()
type Props = Omit<NuxtLinkProps, 'noPrefetch'> & {
  block?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  prefetch: false
})
const isActive = computed(() => {
  const to = typeof props.to === 'string' ? props.to : (props.to as RouteLocationPathRaw)?.path
  return route.path.includes(to)
})
</script>

<template>
  <NuxtLink
    v-wave
    v-bind="$props"
    :class="[{ active: isActive, block }]"
  >
    <slot />
  </NuxtLink>
</template>

<style scoped>
.block {
  display: inline-flex;
}
</style>
